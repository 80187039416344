<template>
  <div class="home">
    <x-header back>实验详情</x-header>
    <x-content
      v-if="loading"
      style="height: 100%"
      :contentStyle="{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }"
    >
      <bounce-loading></bounce-loading>
    </x-content>
    <x-content
      v-else
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 60px)',
        padding: '16px',
        marginTop: '15px',
        background: '#fff',
      }"
    >
      <template v-if="isReview == '0'">
        <action-panel :experimentObj="experimentObj"></action-panel>
      </template>
      <template v-if="isReview == '4'">
        <action-panel
          :experimentObj="experimentObj"
          :isOnlyCopy="true"
        ></action-panel>
      </template>
      <div class="detai">
        <div class="detai_info">
          <div class="detail_title">
            {{ experimentObj.subject }}
            <el-tag
              size="small"
              effect="dark"
              :type="
                experimentObj.status == '0'
                  ? 'info'
                  : experimentObj.status == '1'
                  ? 'success'
                  : experimentObj.status == '2'
                  ? 'danger'
                  : experimentObj.status == '3'
                  ? 'warning'
                  : ''
              "
            >
              {{
                experimentObj.status == "0"
                  ? "待复核"
                  : experimentObj.status == "1"
                  ? "复核通过"
                  : experimentObj.status == "2"
                  ? "复核拒绝"
                  : experimentObj.status == "3"
                  ? "待提交"
                  : experimentObj.status == "4"
                  ? "已归档"
                  : ""
              }}
            </el-tag>
          </div>

          <div
            v-if="
              isReview == '0' &&
              experimentObj.reason &&
              (experimentObj.status == '1' || experimentObj.status == '2')
            "
            class="detail_metaInfo"
            style="padding: 3px; background: #eee"
          >
            <span class="title">审核意见</span>
            <span>{{ experimentObj.reason }}</span>
          </div>
          <div class="detail_metaInfo">
            <span class="title">实验编号</span>
            <span>{{ experimentObj.experiment_id }}</span>
          </div>
          <div
            class="detail_metaInfo"
            v-if="experimentObj.date && experimentObj.date != ''"
          >
            <span class="title">实验时间</span>
            <span>{{ experimentObj.date }}</span>
          </div>

          <!-- 
                    <div class="detail_metaInfo" v-if="experimentObj.content&&experimentObj.content!=''">
                        <span class="title">实验内容</span>
                        <span>{{experimentObj.content}}</span>
                    </div>
                    <div class="detail_metaInfo" v-if="experimentObj.ins">
                        <span class="title">实验地点</span>
                        <span>{{experimentObj.ins}}</span>
                    </div>
                 
                    <div class="detail_metaInfo" v-if="experimentObj.result&&experimentObj.result!=''">
                        <span class="title">实验结果</span>
                        <span>{{experimentObj.result}}</span>
                    </div>-->

          <div
            v-for="(value, key) in experimentObj.template_data"
            :key="key"
            v-if="value"
            class="detail_metaInfo"
          >
            <span class="title">{{ ruleFieldMap[key] }}</span>
            <span
              v-if="typeof value == 'string' && isHTML(value)"
              v-html="value"
            ></span>
            <div
              v-else-if="isMarkDown(value)"
              v-html="renderMarkdown(value)"
            ></div>
            <span
              v-else-if="
                typeof value == 'string' && value.includes('data:image/')
              "
              class="fenziImg"
            >
              <el-image
                title="点击查看原图"
                style="width: 300px; padding: 20px; border: 1px solid #eee"
                :src="value"
                :preview-src-list="[value]"
              ></el-image>
            </span>
            <span
              v-else-if="typeof value == 'object' && value.mol && value.png"
              class="fenziImg"
            >
              <el-image
                title="点击查看原图"
                v-if="value && value.png"
                style="width: 300px; padding: 20px; border: 1px solid #eee"
                :src="value.png"
                :preview-src-list="[value.png]"
              ></el-image>
            </span>
            <span v-else v-html="value"></span>
          </div>
          <div
            class="detail_metaInfo"
            v-if="
              experimentObj.keyword_tags &&
              experimentObj.keyword_tags.length > 0
            "
          >
            <span class="title">自定义标签</span>
            <el-tag
              :key="tag"
              v-for="tag in experimentObj.keyword_tags"
              type="success"
              size="medium"
              style="margin-right: 10px"
              >{{ tag }}
            </el-tag>
          </div>
          <!--<div ref="previewMarkDown" ></div> -->

          <el-divider
            v-if="experimentObj.annex && experimentObj.annex.length > 0"
          ></el-divider>
          <div
            class="Detail__annex"
            v-if="experimentObj.annex && experimentObj.annex.length > 0"
          >
            <div class="Detail__info--title">附件信息</div>
            <div
              :class="
                isMobileClient
                  ? 'Detail__annex--warp--phone'
                  : 'Detail__annex--warp'
              "
            >
              <div
                title="点击下载附件"
                @click="downloadAnnex(item)"
                :class="isMobileClient ? 'Annex__item--phone' : 'Annex__item'"
                v-for="(item, index) in experimentObj.annex"
                :key="'annex' + index"
              >
                <div v-if="!isMobileClient" class="Annex__type">
                  {{
                    item.name
                      .split(".")
                      [item.name.split(".").length - 1].toUpperCase()
                  }}
                </div>
                <div class="Annex__name">
                  {{ item.name }}
                  <b
                    class="el-icon-download"
                    style="color: #409eff; font-weight: 600"
                  ></b>
                </div>
              </div>
            </div>
          </div>
          <template v-if="isReview == '1' && '0' == experimentObj.status">
            <el-divider></el-divider>
            <div class="Detail__annex">
              <div class="Detail__info--title">复核</div>
              <template>
                <el-form>
                  <el-form-item label="复核状态">
                    <el-radio-group v-model="status">
                      <el-radio :label="1">复核通过</el-radio>
                      <el-radio :label="2">复核拒绝</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="复核意见">
                    <el-input
                      type="textarea"
                      style="width: 600px"
                      :autosize="{ minRows: 2, maxRows: 4 }"
                      placeholder="请输入复核意见"
                      v-model="reason"
                    >
                    </el-input>
                  </el-form-item>
                  <template>
                    <el-form-item label="评分" v-if="status == 1">
                      <el-rate
                        style="line-height: 50px !important"
                        v-model="reviewScore"
                        show-text
                      >
                      </el-rate>
                    </el-form-item>
                  </template>
                </el-form>
              </template>
            </div>
            <div ref="userSignatureDialog">
              <div class="detail_metaInfo" ref="addDialog">
                <span class="title">复核签名</span>
                <el-form :model="userSignature" ref="userSignatureForm">
                  <div style="display: flex; justify-content: center">
                    <vue-esign
                      ref="esign"
                      class="mySign"
                      :width="600"
                      :height="300"
                      :isCrop="false"
                      :lineWidth="6"
                      lineColor="#000000"
                      bgColor.sync="bgColor"
                    />
                  </div>
                  <el-form-item label="签名密码">
                    <el-input
                      style="width: 600px"
                      v-model="userSignature.password"
                      show-password
                      @clear="userSignature.password = ''"
                      placeholder="请输入签名密码，密码格式为6位数字，下次输入密码即可完成电子签名"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div class="detail_metaInfo">
                <el-button
                  type="warning"
                  style="width: 5%"
                  size="medium"
                  @click="resetSign"
                  >清空签名</el-button
                >
                <el-button
                  type="primary"
                  style="width: 5%"
                  size="medium"
                  @click="saveSignImg"
                  >确定</el-button
                >
              </div>
            </div>
            <div ref="userPassword">
              <el-form>
                <el-form-item v-model="password" label="签名密码">
                  <el-input
                    style="width: 600px"
                    v-model="password"
                    placeholder="请输入签名密码"
                    show-password
                  ></el-input>
                </el-form-item>
                <div class="save_btn_warp">
                  <el-button
                    type="primary"
                    @click="verify"
                    style="width: 200px"
                    size="medium"
                    >确定</el-button
                  >
                </div>
              </el-form>
            </div>
          </template>
        </div>
      </div>
    </x-content>
  </div>
</template>

<script>
import { GetDetail, ReviewExperiment } from "@/service/experiment";
import { Detail } from "@/service/formTemplate";
import { GetFileV2, GetUserDetails } from "@/service/user";
import { GetSignature, SignatureSave, Verify } from "@/service/userSignature";
import { marked } from "marked";
import 'mathjax/es5/tex-chtml';
export default {
  name: "ExperimentDetail",
  components: {},
  data() {
    return {
      loading: false,
      id: "",
      experimentObj: {},
      ruleFieldMap: {},
      markList: [],
      checkMarkList: [],
      markIdObj: {},
      isReview: "",
      status: 1,
      review_user: { sign_img: "" },
      groupObj: {},
      groupList: [],
      checkGroupList: [],
      groupIdUserMap: {},
      checkShareUserList: [],
      groupIdNameMap: {},
      noteId: "",
      noteMap: {},
      password: "",
      reviewScore: "",
      reason: "",
      userSignature: {},
      shareList: [],
      currentUser: {},
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.isReview = this.$route.params.isReview;
    this.getDetail();
    this.getUser();
  },
  
  methods: {
    renderMarkdown(value) {
      // 调用 marked 函数将 Markdown 转换为 HTML
      let str=  marked(value)
      // 等待 DOM 更新后，调用 MathJax 渲染数学公式
      this.$nextTick(() => {
        MathJax.typesetPromise().then(() => {
        }).catch((err) => console.error(err.message));
      });
      return str;
    },
    isHTML(str) {
      const tags = /<\/?("[^\"]*"|'[^\']*'|[^\>])*>/gi;
      return tags.test(str);
    },
    isMarkDown(str) {
      const markdownPattern =
        /(^#{1,6}\s)|(^-\s)|(^\d+\.\s)|(\[(.*?)\]\((.*?)\))|(^> |\*\*|__|\*|_)/g;
      return markdownPattern.test(str);
    },
    getSignature() {
      GetSignature()
        .then((e) => {
          if (e.data) {
            this.userSignature = e.data;
            this.signImg = this.userSignature.signature;
            this.$refs["userSignatureDialog"].style.display = "none";
            this.$refs["userPassword"].style.display = "block";
          } else {
            this.$refs["userSignatureDialog"].style.display = "block";
            this.$refs["userPassword"].style.display = "none";
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally((e) => {});
    },
    getUser() {
      GetUserDetails()
        .then((e) => {
          this.currentUser = e.data;
        })
        .catch((e) => {
          this.$message.error(e.message);
        })
        .finally((e) => {});
    },
    verify() {
      if (!this.status) {
        this.$message.warning("请选择复核状态！");
        return;
      }
      if (!this.reason) {
        this.$message.warning("请输入复核意见！");
        return;
      }
      Verify(this.password).then((e) => {
        let data = this.APIParse(e);
        if (data) {
          this.password = "";
          this.reviewExperimentSuccess();
        } else {
          this.$message.error("签名密码输入错误，请检查");
          this.password = "";
        }
      });
    },
    reviewExperimentSuccess() {
      if (!this.status) {
        this.$message.warning("请选择复核状态！");
        return;
      }
      if (!this.reason) {
        this.$message.warning("请输入复核意见！");
        return;
      }
      let param = [];
      const updatedReviewUsers = [];
      //this.review_user.sign_img = this.userSignature.signature
      this.review_user = this.experimentObj.review_user;
      if (Array.isArray(this.review_user)) {
        this.review_user.forEach((item) => {
          const updatedItem = { ...item };
          if (updatedItem.id === this.currentUser.id) {
            updatedItem.sign_img = this.userSignature.signature; // 添加 sign_img 属性
          }
          updatedReviewUsers.push(updatedItem);
        });
        this.review_user = updatedReviewUsers;
      } else {
        this.review_user.sign_img = this.userSignature.signature;
      }
      param.push({
        uuid: this.experimentObj.uuid,
        status: this.status,
        reason: this.reason,
        review_score: this.reviewScore,
        group_id: this.experimentObj.group_id,
        user: this.experimentObj.user,
        review_user: this.review_user,
      });
      this.$message.info("实验复核作上链中，请耐心等待");
      this.loading = true;
      ReviewExperiment(param).then((e) => {
        this.loading = false;

        if (e.code == -1) {
          this.$message.error(e.msg);
          return;
        }
        let data = this.APIParse(e);
        this.$message.success("复核成功");
        this.$router.push("/manager/experiment/review/1");
      });
    },
    getDetail() {
      this.loading = true;
      GetDetail(this.id).then((e) => {
        this.loading = false;
        let data = this.APIParse(e);
        this.experimentObj = data?.[0]?._source;
        if (this.isReview == "1" && "0" == this.experimentObj.status) {
          this.getSignature();
        }
        if (this.experimentObj.template_data) {
          this.experimentObj.template_data = JSON.parse(
            this.experimentObj.template_data
          );
          /** 
                     setTimeout(() => {
                    let data=this.experimentObj.template_data
                    for(let obj in data){
                      if(typeof(data[obj])=='string'&&data[obj].indexOf('> #')!=-1){
                        this.convertMarkdownToHtml(data[obj])
                      }
                    }
                    }, 100);
                    */
        }
        Detail(this.experimentObj.form_template_id).then((formTemp) => {
          let formData = this.APIParse(formTemp);
          if (formData.rule) {
            let rule = JSON.parse(formData.rule);
            rule.forEach((ruleObj) => {
              this.$set(this.ruleFieldMap, ruleObj.field, ruleObj.title);
            });
          }
        });
      });
    },
    downloadAnnex(item) {
      GetFileV2(item.url, item.name);
    },
    resetSign() {
      this.$refs.reviewEsign.reset();
    },
    saveSignImg() {
      if (!this.userSignature.password) {
        this.$message({
          type: "warning",
          message: "请输入签名密码",
        });
        return;
      }
      var patten = /^\d{6}$/;
      if (!patten.test(this.userSignature.password)) {
        this.$message("请输入正确的密码格式");
        return false;
      }
      this.$refs.esign
        .generate() // 使用生成器调用把签字的图片转换成为base64图片格式
        .then((res) => {
          // 构造 FormData，并将签名图片对象和签名密码作为字段传入
          this.signImg = res;
          // this.$refs['addDialog'].hide();
          // this.param.sign_img = res;a
          this.userSignature.signature = res;
          if (!this.userSignature.signature) {
            this.$message({
              type: "warning",
              message: "您还没有签名",
            });
            return;
          }
          SignatureSave(this.userSignature).then((e) => {
            if (e.code == -1) {
              this.$message.error(e.msg);
              return;
            }
            let data = this.APIParse(e);
            this.reviewExperimentSuccess();
          });
        })
        .catch((err) => {
          // 画布没有签字时会执行这里提示一下
          this.$message({
            type: "warning",
            message: "您还没有签名",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.Width-100 {
  width: 100%;
}
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.detai {
  padding: 10px;
}
.detail_title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  margin: 20px 0px;
}
.mark_color {
  background: yellow;
}
.detail_metaInfo + .detail_metaInfo {
  //margin-bottom: 16px;
}
.detail_metaInfo {
  display: flex;
  line-height: 1.67;
  align-items: center;
  margin-bottom: 16px;
}
.detail_metaInfo .title {
  font-weight: 600;
  margin-right: 30px;
  color: #8590a6;
  min-width: 80px;
}
.mySign {
  border: 1px dashed #000;
}
.detail_metaInfo span {
  word-break: break-word;
}
.Detail__annex {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.Detail__info--title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

.Detail__annex--warp {
  width: 98%;
  margin: 0 auto;
  padding: 16px;
  height: auto;
  overflow: hidden;
}
.Detail__annex--warp--phone {
  width: 100%;
  margin: 0 auto;
  padding: 4px;
  height: auto;
  overflow: hidden;
}
.Annex__item {
  width: calc(100% - 32px);
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  &:hover {
    background: #f6f6f6;
  }
}
.Annex__item--phone {
  width: calc(100% - 8px);
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  &:hover {
    background: #f6f6f6;
  }
}
.Annex__type {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background: #67c23a;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 50px;
}
.Annex__name {
  margin-left: 16px;
  margin-left: 16px;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
}
.Annex__btn {
  width: auto;
}
.save_btn_warp {
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss" >
.fenziImg .base-image-viewer__mask {
  opacity: 0.95;
  background: #eceef6;
  //background: #fff;
}

.detail_metaInfo table {
  border-collapse: collapse;
  margin-bottom: 10px;
}
.detail_metaInfo table,.detail_metaInfo  th,.detail_metaInfo  td {
  border: 1px solid #bfc3de;
}
</style>